import React, {useEffect, useRef, useState} from 'react';
import {deleteMessage, getMessages, getUpdate} from "../http/messageAPI";
import FilterBar from "../components/FilterBar";
import {Accordion, Button, Container, Form, InputGroup, Stack} from "react-bootstrap";
import CustomCard from "../components/CustomCard";
import {Link, useNavigate} from "react-router-dom";
import ScrollToTopButton from "../components/ScrollToTopButton";
import {addKeysForBlackList} from "../http/keysForBlackListAPI";

const Home = ({user, setModalSettings}) => {
    const navigate = useNavigate();
    const [filters, setFilters] = useState(JSON.parse(localStorage.getItem('filters')) || {
        operation: -1, place: -1, time: 0, showWithoutUsernames: false, showIsBanned: true, reverse: true
    });
    const [messages, setMessages] = useState([]);
    const [filteredMessages, setFilteredMessages] = useState([]);
    const [groupsFilteredMessage, setGroupsFilteredMessages] = useState([]);
    const [showFilter, setShowFilter] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const [selectedText, setSelectedText] = useState('');
    const [showControlButtons, setShowControlButtons] = useState({status: false, mouseInfo: {}});
    const controlButtonsRef = useRef(null);

    const handleMouseUp = (e) => {
        const text = getSelectedText();
        if (text) {
            setSelectedText(text);
            setShowControlButtons({status: true, mouseInfo: e});
        }
    };

    const handleClickOnControlButton = () => {
        if (selectedText){
            addKeysForBlackList(selectedText)
                .catch(console.error)
            setShowControlButtons({...showControlButtons, status: false});
        }
    }

    useEffect(() => {
        if (controlButtonsRef.current && showControlButtons.status) {
            controlButtonsRef.current.style.left = `${showControlButtons.mouseInfo.pageX}px`;
            controlButtonsRef.current.style.top = `${showControlButtons.mouseInfo.pageY}px`;
        }
    }, [showControlButtons]);

    const getSelectedText = () => {
        let text = '';
        if (window.getSelection) {
            text = window.getSelection().toString();
        } else if (document.selection && document.selection.type !== 'Control') {
            text = document.selection.createRange().text;
        }
        return text;
    };

    useEffect(() => {
        const sec = new Date().getTime();
        const hours = filters.time === 0 ? 48 : filters.time === 1 ? 24 : filters.time === 2 ? 10 : 5;
        const filterSec = sec - (3.6 * 10 ** 6) * hours;
        const newState = [...messages.filter(message => (filters.operation === -1 ? true : message.operation === filters.operation) && (filters.place === -1 ? true : message.place === filters.place) && (filters.showWithoutUsernames ? true : message["telegramUser"]?.["userName"]) && (filters.showIsBanned ? true : message["telegramUser"]?.["isBlackList"] === false) && message.time >= filterSec)];
        if (filters.reverse) setFilteredMessages(newState); else setFilteredMessages(newState.reverse());

        localStorage.setItem("filters", JSON.stringify(filters));
    }, [filters, messages]);

    useEffect(() => {
        const result = [];
        for (const message of filteredMessages) {
            const findGroup = result.find(
                el => el.messages[0].message.toLowerCase() === message.message.toLowerCase() &&
                    !el.messages.some(elMess => elMess.id === message.id) &&
                    el.messages[0].time - message.time <= 600000 &&
                    el.messages[0].telegramUserId === message.telegramUserId
            )
            if (findGroup) {
                findGroup.messages.push(message);
            } else {
                result.push({
                    visible: groupsFilteredMessage.find(el => el.id === message.id)?.visible || false,
                    id: message.id,
                    messages: [message]
                })
            }
        }
        setGroupsFilteredMessages([...result]);
    }, [filteredMessages, messages])

    useEffect(() => {
        if (user.login) {
            messagesWorker();
        }
    }, [user]);
    useEffect(() => {
        setFilteredMessages([...filteredMessages].reverse());
    }, [filters.reverse]);
    useEffect(() => {
        let timer;
        if (user.login) timer = setInterval(() => messagesWorker(messages.length > 0 ? messages[0].time : null), 10000);
        return () => {
            clearInterval(timer);
        }
    }, [messages]);

    const groupVisibleHandler = (groupId) => {
        const group = groupsFilteredMessage.find(el => el.id === groupId);
        if (group) {
            group.visible = !group.visible;
            setGroupsFilteredMessages([...groupsFilteredMessage]);
        }
    }
    const messagesWorker = async (time = null) => {
        if (time) {
            const messagesUpdate = await getUpdate(time)
            if (messagesUpdate.length > 0) setMessages([...messagesUpdate, ...messages]);
        } else {
            const messagesUpdate = await getMessages()
            if (messagesUpdate.length > 0) setMessages([...messagesUpdate, ...messages]);
        }
    }

    return (<Container style={{height: "95%"}}
                       className={"mt-2 col-xl-4 col-lg-6 col-md-8 d-flex flex-column align-items-center"}
    >
        {/*<Link to={"/admin"} style={{color: "white", textDecoration: "none"}} className={"mt-2 mb-2"}>Вход в Админ*/}
        {/*    панель</Link>*/}
        <div className={'d-flex justify-content-center w-100'}>
            <Button
              onClick={()=>navigate('/admin')}
              variant={'secondary'}
              className={'mb-2'}
            >
                Вход в админ панель
            </Button>
            <Link style={{marginLeft: 10}} to={'https://bezpaleva.parsus-m.ru'} target={"_blank"}>
                <Button variant={'danger'}>
                    Bezpaleva
                </Button>
            </Link>
        </div>
        <Button
            onClick={() => setShowFilter(!showFilter)}
            className={'mt-2'}
        >
            {showFilter ? 'Скрыть фильтры' : 'Показать фильтры'}
        </Button>
        {showFilter && <FilterBar filters={filters} setFilters={setFilters}/>}
        <Container className={'mt-4 col-11'}>
            <InputGroup>
                <Form.Control
                    rows={1}
                    as={'textarea'}
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    className={'bg-dark text-white'}
                />
                <Button
                    disabled={!searchQuery}
                    onClick={() => window.open(`./messages/${searchQuery}`, '_blank')}
                >
                    Поиск
                </Button>
            </InputGroup>
        </Container>
        <Container
            className={"d-flex col-xl-6 col-lg-6 col-md-8 col-sm-10 flex-row justify-content-between mt-4 switch-container"}>
            <Form.Check className={"mb-3 text-white w-25 d-flex justify-content-center"}
                        type={"switch"} id="switch-usernames" checked={filters.showWithoutUsernames}
                        onChange={() => setFilters({
                            ...filters, showWithoutUsernames: !filters.showWithoutUsernames
                        })}
                        label={filters.showWithoutUsernames ? "All" : "User"}
            />
            <Form.Check className={"mb-3 text-white d-flex justify-content-center"}
                        type={"switch"} id="switch-banned" checked={filters.showIsBanned}
                        onChange={() => setFilters({
                            ...filters, showIsBanned: !filters.showIsBanned
                        })}
                        label={filters.showIsBanned ? "⛔️" : "✅"}
            />
            <Form.Check className={"mb-3 text-white d-flex justify-content-center"}
                        type={"switch"} id="switch-reverse" checked={filters.reverse}
                        onChange={() => setFilters({...filters, reverse: !filters.reverse})}
                        label={filters.reverse ? "5-1" : "1-5"}
            />
        </Container>
        {filteredMessages.length === 0 ? <h1 style={{color: "white"}}>Сообщения отсутствуют</h1> :

            <Stack style={{overflowY: "scroll"}}
                   className="webkitScroll col-11 mx-auto"
                   onMouseUp={handleMouseUp}
            >
                {/*{filteredMessages.map((value, index) => <CustomCard key={`card-${index}-${value.id}`}*/}
                {/*													message={value}*/}
                {/*													messages={messages}*/}
                {/*													setMessages={setMessages}*/}
                {/*													setModalSettings={setModalSettings}/>)}*/}
                {
                    groupsFilteredMessage.map(group =>
                        <div key={`card-main-${group.id}`}>
                            <CustomCard
                                message={group.messages[0]}
                                messages={messages}
                                setMessages={setMessages}
                                setModalSettings={setModalSettings}
                                isVisible={group.visible}
                            />
                            {group.messages.length > 1 &&
                                <Button
                                    style={{backgroundColor: "#002D4C"}}
                                    className={`w-100 ${group.visible ? 'border-isExpand-card' : 'border-notExpand-card'}`}
                                    onClick={() => groupVisibleHandler(group.id)}
                                >{(group.visible ? 'Скрыть' : 'Раскрыть') + ` ${group.messages.length - 1}`}</Button>
                            }
                            {group.visible && group.messages.slice(1).map(message =>
                                <CustomCard
                                    key={`card-${group.id}-${message.id}`}
                                    message={message}
                                    messages={messages}
                                    setMessages={setMessages}
                                    setModalSettings={setModalSettings}
                                    isVisible={group.visible}
                                />
                            )}
                        </div>
                    )
                }
            </Stack>

        }
        <ScrollToTopButton/>
        {
            showControlButtons.status &&
            <div ref={controlButtonsRef} style={{position: 'absolute'}}
                onMouseLeave={()=>setShowControlButtons({...showControlButtons, status: false})}
            >
                <Button onClick={handleClickOnControlButton}>BlackWord</Button>
            </div>
        }
    </Container>);
};

export default Home;